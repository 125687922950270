import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { SpecialtyModel } from "../../domains/app/app.types";
import { ProfessionModel } from "../../domains/profession/profession.types";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { isUserOnboarded } from "../../domains/user/utils/isUserOnboarded";
import { getProfessionsList } from "../../domains/profession/endpoints/getProfessionsList";
import { uploadAvatar } from "../../domains/image/endpoints/uploadAvatar";
import { patchUser } from "../../domains/user/endpoints/patchUser";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";
import { getUserStartingPage } from "../../domains/user/utils/getUserStartingPage";
import disableScroll from "disable-scroll";
import OnboardingProfile from "../onboarding/OnboardingProfile";
import OnboardingProfession from "../onboarding/OnboardingProfession";
import OnboardingSpecialty from "../onboarding/OnboardingSpecialty";
import OnboardingAvatar from "../onboarding/OnboardingAvatar";
import OnboardingSuccessFinal from "../onboarding/OnboardingSuccessFinal";
import { OnboardingSuccessWrapper } from "../../components/onboarding/layout";
import Lottie from "lottie-react";
import JuisciFillTube from "../../assets/animations/tube-filling.json";
import {
  gaEventCompleteOnboarding,
  gaEventViewOnboarding,
} from "../../tools/analytics/authAnalytics";
import { SoundEffect } from "../../interfaces";
import { iosPlaySound } from "../../tools/ios";
import { displayToast } from "../../components/app/AppToast";

interface OnboardingUserData {
  firstName: string;
  lastName: string;
  birthDate: string | null;
  country: string;
  profession: ProfessionModel | null;
  mainSpecialty: SpecialtyModel | null;
  customAvatar: string;
  defaultAvatar: string;
}

export default function SignupOnboardingPage() {
  const history = useHistory();
  const { t } = useLangContext();
  const { currentUser } = useCurrentUser();
  const [isLoadingUser, setLoadingUser] = useState(false);
  const [isLoadingProfessions, setLoadingProfessions] = useState(false);
  const [professions, setProfessions] = useState<ProfessionModel[] | []>([]);
  const [userData, setUserData] = useState<OnboardingUserData>({
    firstName: currentUser?.firstname ?? "",
    lastName: currentUser?.lastname ?? "",
    birthDate: currentUser?.birthdate ?? null,
    country: currentUser?.country ?? "",
    profession: currentUser?.profession ?? null,
    mainSpecialty: currentUser?.main_specialty ?? null,
    customAvatar: currentUser?.profilePicture?.url ?? "",
    // NOTE: A default avatar is randomly assigned to users from the backend.
    // We clear it here.
    defaultAvatar: "",
  });
  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off();
        window.scrollTo(0, 0);

        const { isRedirected } = await preflightUser({
          history,
          authOnly: true,
        });
        if (isRedirected) return;

        if (isUserOnboarded(currentUser)) {
          const path = await getUserStartingPage(currentUser);
          return history.replace(path ?? "/discovery");
        }

        if (!currentUser) {
          // Update user data post-signup
          setLoadingUser(true);
          const res = await getCurrentUser();
          setUserData({
            firstName: res.data?.firstname ?? "",
            lastName: res.data?.lastname ?? "",
            birthDate: res.data?.birthDate ?? null,
            country: res.data?.country ?? "",
            profession: res.data?.profession ?? null,
            mainSpecialty: res.data?.main_specialty ?? null,
            customAvatar: res.data?.profilePicture?.url ?? "",
            // NOTE: A default avatar is randomly assigned to users from the backend.
            // We clear it here.
            // defaultAvatar: res.data?.profilePicture?.name ?? "",
            defaultAvatar: "",
          });
          setLoadingUser(false);
        }
        gaEventViewOnboarding();
      } catch (error) {
        console.error("Could't mount onboarding page.", error);
        displayToast(t("error:default"));
        history.replace("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (userData.profession === null && !professions.length) {
      loadProfessions();
    }

    if (
      userData.firstName &&
      userData.lastName &&
      userData.country &&
      userData.profession &&
      userData.mainSpecialty &&
      (userData.customAvatar || userData.defaultAvatar)
    ) {
      finalizeOnboarding();
    }
  }, [userData]);

  async function loadProfessions() {
    try {
      setLoadingProfessions(true);
      setProfessions(await getProfessionsList());
      setLoadingProfessions(false);
    } catch (error) {
      console.error("Could't load professions.", error);
      displayToast(t("error:default"));
      history.replace("/login");
    }
  }

  async function uploadUserAvatar() {
    try {
      const formData = new FormData();
      if (userData.defaultAvatar) {
        formData.set("pictureName", userData.defaultAvatar);
      } else {
        const res = await fetch(userData.customAvatar);
        const blob = await res.blob();
        const file = new File([blob], "avatar", { type: "image/jpeg" });
        formData.set("file", file);
      }
      await uploadAvatar(formData);
    } catch (error) {
      // NOTE: Silent error. Onboarding is done even if avatar couldn't be updloaded.
      console.error("Couldn't upload avatar", error);
    }
  }

  function handleUpdateProfile(payload: {
    firstName: string;
    lastName: string;
    birthDate: string | null;
    country: string;
  }) {
    setUserData({
      ...userData,
      ...payload,
    });
  }

  async function handleUpdateProfession(profession: ProfessionModel) {
    setUserData({
      ...userData,
      profession,
    });
  }

  async function handleUpdateSpecialty(specialty: SpecialtyModel) {
    setUserData({
      ...userData,
      mainSpecialty: specialty,
    });
  }

  async function handleUpdateAvatar({
    customAvatar,
    defaultAvatar,
  }: {
    customAvatar: string;
    defaultAvatar: string;
  }) {
    setUserData({
      ...userData,
      customAvatar,
      defaultAvatar,
    });
  }

  async function handleCancelProfession() {
    setUserData({
      ...userData,
      profession: null,
    });
  }

  async function handleCancelSpecialty() {
    setUserData({
      ...userData,
      mainSpecialty: null,
    });
  }

  async function finalizeOnboarding() {
    try {
      await uploadUserAvatar();
      await patchUser({
        firstname: userData.firstName,
        lastname: userData.lastName,
        birthDate: userData.birthDate,
        country: userData.country,
        profession: userData.profession?.uid,
        main_specialty: userData.mainSpecialty?.uid,
      });
      await getCurrentUser();

      setTimeout(() => {
        iosPlaySound(SoundEffect.LIKE);
        gaEventCompleteOnboarding();
      }, 500);

      setTimeout(async () => {
        localStorage.removeItem("seenDownloadAppModal");
        history.replace("/login/video");
      }, 3000);
    } catch (error) {
      console.error("Couldn't finalize onboarding.", error);
      displayToast(t("error:default"));
      history.replace("/login");
    }
  }

  if (isLoadingUser || isLoadingProfessions) {
    return (
      <OnboardingSuccessWrapper>
        <Lottie
          className='lottie-animation'
          style={{
            width: 64,
            minHeight: "70dvh",
          }}
          animationData={JuisciFillTube}
          loop={true}
          autoplay={true}
        />
      </OnboardingSuccessWrapper>
    );
  }

  if ([
    userData.firstName,
    userData.lastName,
    userData.country,
  ].some((field: string) => field.length === 0)) {
    return <OnboardingProfile userData={userData} onSubmit={handleUpdateProfile} />;
  }

  if (!userData.profession) {
    return (
      <OnboardingProfession
        professions={professions}
        onSubmit={handleUpdateProfession}
      />
    );
  }

  if (!userData.mainSpecialty) {
    return (
      <OnboardingSpecialty
        profession={userData.profession}
        onCancel={handleCancelProfession}
        onSubmit={handleUpdateSpecialty}
      />
    );
  }

  if (!userData.customAvatar && !userData.defaultAvatar) {
    return (
      <OnboardingAvatar
        onCancel={handleCancelSpecialty}
        onSubmit={handleUpdateAvatar}
      />
    );
  }

  return <OnboardingSuccessFinal />;
}
